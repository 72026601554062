<template>
  <!--begin::Documents toolbar-->
  <div class="row">
    <!--begin::Table-->
    <div class="col">
      <DataTable :value="informes" class="table table-flush table-row-gray-300">
        <Column
          v-for="(info, key) in columns"
          :key="key"
          :field="info.field"
          :header="info.header"
        ></Column>
      </DataTable>
    </div>
    <!--end::Table-->
  </div>
  <!--end::Documents toolbar-->
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { getReportes } from "@/services/informes";
import moment from "moment-timezone";

export default defineComponent({
  name: "documents",
  components: { DataTable, Column },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Documents", ["Pages", "Profile"]);
      fetchInformes();
      moment.locale("es");
    });
    const informes = ref([]);
    const fetchInformes = () => {
      getReportes()
        .then(({ data }) => {
          informes.value = data.map((e) => {
            let created = moment(e.createdAt)
              .tz("America/Bogota")
              .format("DD MMM Y | H:m");
            let fechaInforme = moment().format("dddd, MMM DD - h:m");
            return {
              fechaInforme,
              created,
              dispositivosLength: e.dispositivos.length,
              correosLength: e.correos.length,
              ...e,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const columns = [
      { field: "nombre", header: "Nombre" },
      { field: "dispositivosLength", header: "Dispositivos" },
      { field: "correosLength", header: "Correos" },
      { field: "created", header: "Fecha Creación" },
      { field: "fechaInforme", header: "Fecha Informe" },
    ];
    return {
      informes,
      columns,
    };
  },
});
</script>
